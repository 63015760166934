<template>
  <div>
    <el-table
      stripe
      :data="list"
      style="width: 100%"
      >
        <el-table-column
          prop="_id"
          label="订单id"
        />
        <el-table-column
          prop="userId"
          label="用户id"
        />
        <el-table-column
          prop="goodsList"
          label="商品列表"
          :formatter="formatOrderGoodsList"
        >
        </el-table-column>
        <el-table-column
          label="支付状态"
          :formatter="formatPayStatus"
        >
        <template slot-scope="scope">
            <el-tag :type="getTagColor(scope.row.payStatus)">{{ formatPayStatus(scope.row.payStatus) }}</el-tag>
            <el-button type="primary" size="mini" @click="refreshPayStatus(scope.row)">刷新</el-button>
            <el-button type="primary" size="mini" @click="refundPay(scope.row)">退款</el-button>
            <el-button type="primary" size="mini" @click="refundPayQuery(scope.row)">退款查询</el-button>
            <el-button type="primary" size="mini" @click="closePay(scope.row)">关闭</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="alipayTradeNo"
          label="支付宝订单号"
        />
        <el-table-column
          prop="payFee"
          label="支付金额"
        />
        <el-table-column
          prop="payTime"
          label="支付更新时间"
        />
        <el-table-column
          prop="remark"
          label="备注"
        />
        <el-table-column
        fixed="right"
        label="操作"
        width="300">
        <template slot-scope="scope">
          <el-button type="primary" @click="createPayOrder(scope.row)" size="small">创建支付</el-button>
          <el-button type="primary" @click="edit(scope.row)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>

    <el-dialog title="编辑订单" :visible.sync="dialogShow" >
      <div>
        <qrcode-vue :value="alipayQrCode" :options="qrcodeOptions" :text='alipayQrCode'></qrcode-vue>
      </div>
      <el-button type="primary" @click="dialogShow=false" >关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode-component'

export default {
  name: '',
  components: {
    'qrcode-vue': VueQrcode
  },
  props: {},
  data () {
    return {
      list: [],
      dialogShow: false,
      alipayQrCode: '',
      qrcodeOptions: {
        width: 200,
        height: 200
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('/order')
      this.list = res.data
    },
    edit (row) {
      console.log(row)
    },
    remove (row) {
      this.$confirm(`确定删除 "${row._id}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`/order/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    },
    // 刷新单个订单的支付状态
    async refreshPayStatus (row) {
      const ret = await this.$http.post('/pay/query', {
        out_trade_no: row._id,
        trade_no: ''
      })
      console.log(ret.data)
      const resObj = ret.data.alipay_trade_query_response
      if (resObj) {
        const tradeStatus = resObj.trade_status
        const subMsg = resObj.sub_msg

        this.$message({
          type: tradeStatus ? 'success' : 'info',
          message: tradeStatus !== undefined ? tradeStatus : subMsg
        })

        // 更新本条订单记录的支付状态 payStatus
        const ret2 = await this.$http.post(`/order/updatePayInfo?orderId=${row._id}`, {
          alipayTradeNo: resObj.trade_no,
          payStatus: this.payStatus2OrderStatus(tradeStatus),
          payTime: resObj.send_pay_date,
          payFee: resObj.total_amount
        })
        console.log(ret2.data)
        this.fetch()
      }
    },
    // 退款
    async refundPay (row) {
      const obj = {
        out_trade_no: row._id,
        trade_no: '',
        refund_amount: row.payFee,
        refund_reason: '正常退款',
        out_request_no: ''
      }
      const ret = await this.$http.post('/pay/refund', obj)
      console.log(ret.data)

      this.$message({
        type: 'fail',
        message: `${ret.data.alipay_trade_refund_response.msg} 退款金额：${ret.data.alipay_trade_refund_response.refund_fee}`
      })
    },
    // 退款查询
    async refundPayQuery (row) {
      const obj = {
        out_trade_no: row._id,
        trade_no: '',
        out_request_no: row._id
      }
      const ret = await this.$http.post('/pay/refundquery', obj)
      console.log(ret.data)

      this.$message({
        type: 'fail',
        message: ret.data.alipay_trade_fastpay_refund_query_response.refund_status
      })
    },
    // 关闭订单
    async closePay (row) {
      const obj = {
        out_trade_no: row._id,
        trade_no: ''
      }
      const ret = await this.$http.post('/pay/close', obj)
      console.log(ret.data)
    },
    // 创建支付订单
    async createPayOrder (row) {
      const url = '/pay/precreate'

      // 总金额
      let costAmount = 0
      // body
      let body = ''
      const goodsDetail = []
      row.goodsList.forEach(item => {
        goodsDetail.push({
          goods_id: item.goodsId,
          goods_name: '商品标题',
          quantity: item.number,
          price: item.price,
          goods_category: '',
          show_url: 'https://hkbuy.xyz'
        })

        costAmount += parseFloat(item.price) * item.number
        body += `${item.goodsId} 数量：${item.number} 价格：${item.price}\n`
      })

      costAmount = costAmount.toFixed(2)

      const obj = {
        out_trade_no: row._id,
        total_amount: costAmount,
        subject: '我的订单标题123',
        body,
        discountable_amount: '',
        goods_detail: goodsDetail
      }
      console.log(obj)
      const ret = await this.$http.post(url, obj)
      if (ret.data.alipay_trade_precreate_response && ret.data.alipay_trade_precreate_response.qr_code) {
        this.alipayQrCode = ret.data.alipay_trade_precreate_response.qr_code
        console.log(this.alipayQrCode)

        // 获取支付宝订单号
        const ret3 = await this.$http.post('/pay/query', {
          out_trade_no: row._id,
          trade_no: ''
        })

        if (ret3.data.alipay_trade_query_response && ret3.data.alipay_trade_query_response.trade_no) {
          // 更新订单号
          await this.$http.post(`/order/updatePayInfo?orderId=${row._id}`, {
            alipayTradeNo: ret3.data.alipay_trade_query_response.trade_no,
            payStatus: 0,
            payTime: new Date().getTime(),
            payFee: costAmount
          })
        }

        this.fetch()

        this.dialogShow = true
      } else {
        this.$message({
          type: 'fail',
          message: '创建支付订单失败'
        })
      }
    },
    // 格式化商品列表
    formatOrderGoodsList (row, col) {
      let str = ''
      for (const i of row.goodsList) {
        str += `goodsId: ${i.goodsId} 数量：${i.number} 单价：${i.price}\n`
      }
      return str
    },
    // 格式化支付状态
    formatPayStatus (status) {
      if (status === 0) {
        return '未支付'
      } else if (status === 1) {
        return '待支付'
      } else if (status === 2) {
        return '已支付'
      } else if (status === 3) {
        return '已退款'
      } else {
        return `其他状态${status}`
      }
    },
    // 支付宝接口交易状态字符串转订单状态整形
    payStatus2OrderStatus (status) {
      if (status === 'WAIT_BUYER_PAY') {
        return 0 // 交易创建，等待买家付款
      } else if (status === 'TRADE_CLOSED') {
        return 3 // 未付款交易超时关闭，或支付完成后全额退款
      } else if (status === 'TRADE_SUCCESS') {
        return 2 // 交易支付成功
      } else if (status === 'TRADE_FINISHED') {
        return 4 // 交易结束，不可退款
      }
    },
    // tag颜色
    getTagColor (status) {
      switch (status) {
        case 0:
          return 'info'
        case 1:
          return ''
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'warning'
      }
    }
  }
}

</script>

<style scoped lang="less">
.multi-line-text {
  white-space: pre-line;
}
</style>
