<template>
    <div>
        <el-form :label-width="data.width">
        <el-form-item lable="menu">
          <el-checkbox v-model="data.enable">启用</el-checkbox>
          <el-form-item label="大菜单名称" v-if="data.enable">
            <el-col :span="4">
              <el-input v-model="data.name"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="子菜单" v-if="data.enable">
            <el-switch v-model="data.hasSub" ></el-switch>
          </el-form-item>
          <el-form-item label2="顶级菜单响应" v-if="!data.hasSub && data.enable">
            <el-row :gutter="20">
                <el-col :span="0.5"><div >
                  类型:
                </div></el-col>
                <el-col :span="3"><div  >
                  <el-select placeholder="请选择菜单响应事件" v-model="data.main.type" clearable :disabled="data.hasSub">
                     <el-option  v-for="(itemKey,keyIndex) in keyEvent" :key="keyIndex" :label="itemKey.text" v-model="itemKey.key" size="mini"></el-option>
                  </el-select>
                </div></el-col>
                <el-col :span="0.6" v-if="data.main.type == 'view' || data.main.type == 'miniprogram'"><div >
                  URL:
                </div></el-col>
                <el-col :span="4" v-if="data.main.type == 'view' || data.main.type == 'miniprogram'"><div >
                  <el-input size="medium" v-model="data.main.url" :disabled="data.hasSub"></el-input>
                </div></el-col>
                <el-col :span="0.6" v-if="data.main.type != 'view' && data.main.type != 'miniprogram'"><div >
                  KEY:
                </div></el-col>
                <el-col :span="4" v-if="data.main.type != 'view' && data.main.type != 'miniprogram'"><div >
                  <el-input size="medium" v-model="data.main.key" :disabled="data.hasSub"></el-input>
                </div></el-col>
                <el-col :span="1" v-if="data.main.type == 'miniprogram'"><div >
                  appid:
                </div></el-col>
                <el-col :span="3" v-if="data.main.type == 'miniprogram'"><div  >
                  <el-input size="medium" v-model="data.main.appid" :disabled="data.hasSub"></el-input>
                </div></el-col>
                <el-col :span="1.3" v-if="data.main.type == 'miniprogram'"><div  >
                  pagepath:
                </div></el-col>
                <el-col :span="3" v-if="data.main.type == 'miniprogram'"><div  >
                  <el-input size="medium" v-model="data.main.pagepath" :disabled="data.hasSub"></el-input>
                </div></el-col>
            </el-row>
          </el-form-item>
          <div v-if="data.hasSub && data.enable">
            <el-form-item label2="子菜单响应" v-for="(subItem,index) in data.subItems" :key="index">
              <el-row :gutter="20" >
                <el-col :span="0.5"><div >
                    <el-checkbox v-model="subItem.enable">{{'子菜单 ' + index + ':'}}</el-checkbox>
                </div></el-col>
                <el-col :span="3" ><div >
                  <el-input size="medium" v-model="subItem.name" :disabled="!subItem.enable"></el-input>
                </div></el-col>
                <el-col :span="0.5"><div >
                  类型:
                </div></el-col>
                <el-col :span="3"><div  >
                  <el-select placeholder="请选择菜单响应事件" v-model="subItem.type" clearable :disabled="!subItem.enable">
                     <el-option  v-for="(itemKey,keyIndex) in keyEvent" :key="keyIndex" :label="itemKey.text" v-model="itemKey.key" size="mini"></el-option>
                  </el-select>
                </div></el-col>
                <el-col :span="0.6" v-if="subItem.type == 'view' || subItem.type == 'miniprogram'"><div >
                  URL:
                </div></el-col>
                <el-col :span="4" v-if="subItem.type == 'view' || subItem.type == 'miniprogram'"><div >
                  <el-input size="medium" v-model="subItem.url" :disabled="!subItem.enable"></el-input>
                </div></el-col>
                <el-col :span="0.6" v-if="subItem.type != 'view' && subItem.type != 'miniprogram'"><div >
                  KEY:
                </div></el-col>
                <el-col :span="4" v-if="subItem.type != 'view' && subItem.type != 'miniprogram'"><div >
                  <el-input size="medium" v-model="subItem.key" :disabled="!subItem.enable"></el-input>
                </div></el-col>
                <el-col :span="1" v-if="subItem.type == 'miniprogram'"><div >
                  appid:
                </div></el-col>
                <el-col :span="3" v-if="subItem.type == 'miniprogram'"><div  >
                  <el-input size="medium" v-model="subItem.appid" :disabled="!subItem.enable"></el-input>
                </div></el-col>
                <el-col :span="1.3" v-if="subItem.type == 'miniprogram'"><div  >
                  pagepath:
                </div></el-col>
                <el-col :span="3" v-if="subItem.type == 'miniprogram'"><div  >
                  <el-input size="medium" v-model="subItem.pagepath" :disabled="!subItem.enable"></el-input>
                </div></el-col>
              </el-row>
            </el-form-item>
          </div>
          <el-divider></el-divider>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
export default {
  name: 'WxmpMemuItem',
  props: {
    data: {
      type: Object,
      required: false,
      default: function () {
        return {
          enable: true,
          name: '',
          width: '100px',
          main: {
            type: '',
            key: '',
            url: '',
            appid: '',
            pagepath: ''
          },
          hasSub: false,
          subItems: [
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            }
          ]
        }
      }
    }
  },
  data () {
    return {
      keyEvent: [
        {
          key: 'view',
          text: '跳转URL'
        },
        {
          key: 'click',
          text: 'Event'
        },
        {
          key: 'scancode_push',
          text: '扫码推事件'
        },
        {
          key: 'scancode_waitmsg',
          text: '扫码等待消息'
        },
        {
          key: 'pic_sysphoto',
          text: '系统拍照'
        },
        {
          key: 'pic_photo_or_album',
          text: '拍照or相册发图'
        },
        {
          key: 'pic_weixin',
          text: '微信相册发图'
        },
        {
          key: 'location_select',
          text: '地理位置'
        },
        {
          key: 'miniprogram',
          text: '小程序'
        }
      ]
    }
  },
  methods: {}
}
</script>
