<template>
  <el-form ref="form"  label-width="80px">
    <el-form-item label="商品名称">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <!--el-form-item label="分类">
      <el-select v-model="form.region" placeholder="请选择商品分类">
        <el-option label="分类一" value="shanghai"></el-option>
        <el-option label="分类二" value="beijing"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="即时配送">
      <el-switch v-model="form.delivery"></el-switch>
    </el-form-item>
    <el-form-item label="活动性质">
      <el-checkbox-group v-model="form.type">
        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
        <el-checkbox label="地推活动" name="type"></el-checkbox>
        <el-checkbox label="线下主题活动" name="type"></el-checkbox>
        <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="特殊资源">
      <el-radio-group v-model="form.resource">
        <el-radio label="线上品牌商赞助"></el-radio>
        <el-radio label="线下场地免费"></el-radio>
      </el-radio-group>
    </el-form-item-->
    <el-form-item label="详情">
      <el-input type="textarea" v-model="form.content"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      form: {
        title: '',
        content: '',
        resource: '',
        type: '',
        delivery: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      console.log(this.id)
      const ret = await this.$http.get(`/goods/${this.id}`)
      this.form = ret.data
    },
    async onSubmit () {
      const obj = {
        title: this.form.title,
        content: this.form.content
      }
      if (this.id) {
        const ret = await this.$http.post(`/goods/update/${this.id}`, obj)
        console.log(ret.data)
      } else {
        const ret = await this.$http.post('/goods', obj)
        console.log(ret.data)
      }

      this.$router.push('/goods/list')
    }
  }
}

</script>

<style scoped lang="less">
</style>
