<template>
  <div class="home">
    <el-tabs v-model="tabIndex" type="card" @tab-click="switchTab"  class="tab">
      <!-- 第一个tab页 -->
      <el-tab-pane label="结算收入数据" name="0">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-date-picker
              v-model="queryCondSettlement.dateRange"
              type="monthrange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份">
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="query">查询</el-button>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="1.3">
            <el-tag type="success">主体名称:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="settlement.body" placeholder="主体名称"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="info">累计收入:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="settlement.revenue_all" placeholder="累计收入"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">扣除金额:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="settlement.penalty_all" placeholder="扣除金额"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">已结算金额:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="settlement.settled_revenue_all" placeholder="已结算金额"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-table
          v-loading="loading"
          stripe
          :data="settlement.settlement_list"
          :cell-style="cellStyle"
          style="width: 100%">
          <el-table-column
            prop="date"
            label="数据更新时间">
          </el-table-column>
          <el-table-column
            prop="zone"
            label="日期区间">
          </el-table-column>
          <el-table-column
            prop="month"
            label="收入月份">
          </el-table-column>
          <el-table-column
            prop="order"
            label="上半月/下半月"
            :formatter="fomartterMonth">
          </el-table-column>
          <el-table-column
            prop="sett_status"
            :formatter="fomartterSettlementStatus"
            label="结算状态">
            <template slot-scope="scope">
              <el-tag
                size="medium"
                :type="scope.row.sett_status === 5 ? 'success' : 'primary'"
                disable-transitions>{{fomartterSettlementStatus(scope.row.sett_status)}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="settled_revenue"
            label="区间内结算收入"
            :formatter="formatterSettledValue">
          </el-table-column>
          <el-table-column
            prop="sett_no"
            label="结算单编号">
          </el-table-column>
          <!--el-table-column
            prop="mail_send_cnt"
            label="申请补发结算单次数">
          </el-table-column-->
          <el-table-column
            prop="slot_revenue"
            label="产生收入的广告位"
            :formatter="formatterUnitDetail">
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="queryCondSettlement.total"
          @current-change="changePage">
        </el-pagination>
      </el-tab-pane>
      <!-- 第二个tab页 -->
      <el-tab-pane label="广告位数据" name="1">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-date-picker
              v-model="queryCondUnit.dateRange"
              type="monthrange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份">
            </el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-select v-model="queryCondUnit.unitType" clearable placeholder="选择广告位">
              <el-option
                v-for="item in unitTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="queryUnit">查询</el-button>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="1.3">
            <el-tag type="success">总拉取量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.req_succ_count" placeholder="总拉取量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="info">总曝光量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.exposure_count" placeholder="总曝光量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总曝光率:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.exposure_rate" placeholder="总曝光率"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="1.3">
            <el-tag type="warning">总点击量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.click_count" placeholder="总点击量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总点击率:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.click_rate" placeholder="总点击率"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总收入:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.income" placeholder="总收入"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">广告千次曝光收益:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="UnitData.summary.ecpm" placeholder="广告千次曝光收益"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-table
          v-loading="loadingUnit"
          stripe
          :data="UnitData.list"
          :cell-style="cellStyle"
          style="width: 100%">
          <el-table-column
            prop="slot_id"
            label="广告位类型id">
          </el-table-column>
          <el-table-column
            prop="ad_slot"
            label="广告位类型名称">
            <template slot-scope="scope">
                <p>{{ unit2Name(scope.row.ad_slot) }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="日期">
          </el-table-column>
          <el-table-column
            prop="req_succ_count"
            label="拉取量">
          </el-table-column>
          <el-table-column
            prop="exposure_count"
            label="曝光量">
          </el-table-column>
          <el-table-column
            prop="exposure_rate"
            label="曝光率"
            :formatter="formatterExposureRate">
          </el-table-column>
          <el-table-column
            prop="click_count"
            label="点击量">
          </el-table-column>
          <el-table-column
            prop="click_rate"
            label="点击率"
            :formatter="formatterClickRate">
          </el-table-column>
          <el-table-column
            prop="ecpm"
            label="广告千次曝光收益"
            :formatter="formatterEcpm">
          </el-table-column>
          <el-table-column
            prop="income"
            label="收入"
            :formatter="formatterIncome">
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="queryCondUnit.total"
          @current-change="changePageUnit">
        </el-pagination>
      </el-tab-pane>
      <!-- 第三页 -->
      <el-tab-pane label="返佣商品数据" name="2">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-date-picker
              v-model="queryCondCps.dateRange"
              type="monthrange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份">
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="queryCps">查询</el-button>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="1.3">
            <el-tag type="info">总曝光量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.exposure_count" placeholder="总曝光量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总点击量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.click_count" placeholder="总点击量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总点击率:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.click_rate" placeholder="总点击率"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="1.3">
            <el-tag type="warning">总下单量:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.order_count" placeholder="总下单量"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总下单率:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.order_rate" placeholder="总下单率"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">订单总金额:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.total_fee" placeholder="订单总金额"></el-input>
          </el-col>
          <el-col :span="1.3">
            <el-tag type="warning">总预估收入:</el-tag>
          </el-col>
          <el-col :span="2">
            <el-input v-model="cpsData.summary.total_commission" placeholder="总预估收入"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-table
          v-loading="loadingCps"
          stripe
          :data="cpsData.list"
          :cell-style="cellStyle"
          style="width: 100%">
          <el-table-column
            prop="slot_id"
            label="广告位类型id">
          </el-table-column>
          <el-table-column
            prop="ad_slot"
            label="广告位类型名称">
            <template slot-scope="scope">
                <p>{{ unit2Name(scope.row.ad_slot) }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="日期">
          </el-table-column>
          <el-table-column
            prop="exposure_count"
            label="曝光量">
          </el-table-column>
          <el-table-column
            prop="click_count"
            label="点击量">
          </el-table-column>
          <el-table-column
            prop="click_rate"
            label="点击率"
            :formatter="formatterClickRateCps">
          </el-table-column>
          <el-table-column
            prop="order_count"
            label="订单量">
          </el-table-column>
          <el-table-column
            prop="order_rate"
            label="下单率"
            :formatter="formatterOrderRate">
          </el-table-column>
          <el-table-column
            prop="total_fee"
            label="订单金额"
            :formatter="formatterTotalFee">
          </el-table-column>
          <el-table-column
            prop="total_commission"
            label="预估收入"
            :formatter="formatterCommission">
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="queryCondCps.total"
          @current-change="changePageCps">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      tabIndex: 0,
      loading: false,
      loadingUnit: false,
      loadingCps: false,
      // 结算数据
      queryCondSettlement: {
        page: 1,
        page_size: 10,
        total: 0,
        dateRange: ['2021-05-01', '2021-06-30']
      },
      settlement: {}, // 结算数据
      // 广告位数据
      queryCondUnit: {
        page: 1,
        page_size: 10,
        total: 0,
        dateRange: ['2021-05-01', '2021-06-30'],
        unitType: '' // 广告位类型
      },
      unitTypeList: [
        {
          value: 'SLOT_ID_BIZ_BOTTOM',
          label: '公众号底部广告'
        },
        {
          value: 'SLOT_ID_BIZ_MID_CONTEXT',
          label: '公众号文中广告'
        },
        {
          value: 'SLOT_ID_BIZ_VIDEO_END',
          label: '公众号视频后贴'
        },
        {
          value: 'SLOT_ID_BIZ_SPONSOR',
          label: '公众号互选广告'
        },
        {
          value: 'SLOT_ID_BIZ_CPS',
          label: '公众号返佣商品'
        }
      ],
      UnitData: {
        list: [],
        summary: {
          req_succ_count: '', // 拉取量
          exposure_count: '', // 曝光量
          exposure_rate: '', // 曝光率
          click_count: '', // 点击量
          click_rate: '', // 点击率
          income: '', // 收入
          ecpm: '' // 广告千次曝光收益
        }
      },
      // 返佣商品数据
      queryCondCps: {
        page: 1,
        page_size: 10,
        total: 0,
        dateRange: ['2021-05-01', '2021-06-30']
      },
      cpsData: {
        list: [],
        summary: {
          exposure_count: '', // 总曝光量
          click_count: '', // 总点击量
          click_rate: '', // 总点击率
          order_count: '', // 总下单量
          order_rate: '', // 总下单率
          total_fee: '', // 订单总金额
          total_commission: '' // 总预估收入
        }
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch(0)
  },
  mounted () {},
  methods: {
    // 切换tab
    async switchTab (tab, event) {
      console.log(this.tabIndex)
    },
    // 请求接口数据
    async fetch (tabIndex) {
      let url = ''
      if (tabIndex === 0) {
        this.loading = true
        url = `wxmp/ad_settlement?start_date=${this.queryCondSettlement.dateRange[0]}&end_date=${this.queryCondSettlement.dateRange[1]}&page=${this.queryCondSettlement.page}&page_size=${this.queryCondSettlement.page_size}`
      } else if (tabIndex === 1) {
        this.loadingUnit = true
        url = `wxmp/ad_info?start_date=${this.queryCondUnit.dateRange[0]}&end_date=${this.queryCondUnit.dateRange[1]}&page=${this.queryCondUnit.page}&page_size=${this.queryCondUnit.page_size}&ad_slot=${this.queryCondUnit.unitType}`
      } else if (tabIndex === 2) {
        this.loadingCps = true
        url = `wxmp/ad_cps_list?start_date=${this.queryCondCps.dateRange[0]}&end_date=${this.queryCondCps.dateRange[1]}&page=${this.queryCondCps.page}&page_size=${this.queryCondCps.page_size}`
      } else {
        return
      }
      const res = await this.$http.get(url)
      if (tabIndex === 0) {
        this.loading = false
        this.queryCondSettlement.total = res.data.total_num
        this.settlement = res.data
        this.settlement.revenue_all /= 100
        this.settlement.penalty_all /= 100
        this.settlement.settled_revenue_all /= 100
      } else if (tabIndex === 1) {
        this.loadingUnit = false
        this.queryCondUnit.total = res.data.total_num
        this.UnitData = res.data
        this.UnitData.summary.exposure_rate = (this.UnitData.summary.exposure_rate * 100).toFixed(2) + '%'
        this.UnitData.summary.click_rate = (this.UnitData.summary.click_rate * 100).toFixed(2) + '%'
        this.UnitData.summary.income = (this.UnitData.summary.income / 100).toFixed(2)
        this.UnitData.summary.ecpm = (this.UnitData.summary.ecpm / 100).toFixed(2)
      } else if (tabIndex === 2) {
        this.loadingCps = false
        this.queryCondCps.total = res.data.total_num
        this.cpsData = res.data
        this.cpsData.summary.total_commission = (this.cpsData.summary.total_commission / 100).toFixed(2)
        this.cpsData.summary.total_fee = (this.cpsData.summary.total_fee / 100).toFixed(2)
        this.cpsData.summary.order_rate = (this.cpsData.summary.order_rate * 100).toFixed(2) + '%'
        this.cpsData.summary.click_rate = (this.cpsData.summary.click_rate).toFixed(2) + '%'
      }
      // 错误处理
      if (res.data.err_msg) {
        this.$message({
          type: 'error',
          message: `${res.data.err_msg}, code=${res.data.ret}`
        })
      }
    },
    // 查询数据
    async query () {
      this.queryCondSettlement.page = 1
      this.fetch(0)
    },
    // 翻页
    async changePage (page) {
      this.queryCondSettlement.page = page
      this.fetch(0)
    },
    // 翻页
    async changePageUnit (page) {
      this.queryCondUnit.page = page
      this.fetch(1)
    },
    // 设置单元格文字颜色
    cellStyle (row, col, rowIndex, colIndex) {
      if (row.columnIndex === 5 && this.tabIndex === 0) {
        return 'color:red; fontSize:20px'
      }
    },
    // “产生收入的广告位”字段文本格式化
    formatterUnitDetail (row, col) {
      let str = ''
      for (const i of row.slot_revenue) {
        const name = this.unit2Name(i.slot_id)
        const value = i.slot_settled_revenue / 100
        str += `${name} ：${value}\n`
      }
      return str
    },
    // 已结算金额字段文本格式化
    formatterSettledValue (row, col) {
      const val = row.settled_revenue
      return val / 100
    },
    // 结算状态字段文本格式化
    fomartterSettlementStatus (row, col) {
      const val = row
      if (val === 1) {
        return '结算中'
      } else if (val === 2 || val === 3) {
        return '已结算'
      } else if (val === 4) {
        return '付款中'
      } else if (val === 5) {
        return '已付款'
      }
      return `其他状态(${val})`
    },
    // 上半月/下半月字段文本格式化
    fomartterMonth (row, col) {
      const val = row.order
      if (val === 1) {
        return '上半月'
      } else if (val === 2) {
        return '下半月'
      }
      return `其他${val}`
    },
    // 广告位转名称
    unit2Name (unit) {
      if (unit === 'SLOT_ID_BIZ_BOTTOM') {
        return '公众号底部广告'
      } else if (unit === 'SLOT_ID_BIZ_MID_CONTEXT') {
        return '公众号文中广告'
      } else if (unit === 'SLOT_ID_BIZ_VIDEO_END') {
        return '公众号视频后贴'
      } else if (unit === 'SLOT_ID_BIZ_SPONSOR') {
        return '公众号互选广告'
      } else if (unit === '公众号返佣商品') {
        return 'SLOT_ID_BIZ_CPS'
      } else if (unit === 'SLOT_ID_WEAPP_BANNER') {
        return '小程序banner'
      } else if (unit === 'SLOT_ID_WEAPP_REWARD_VIDEO') {
        return '小程序激励视频'
      } else if (unit === 'SLOT_ID_WEAPP_INTERSTITIAL') {
        return '小程序插屏广告'
      } else if (unit === 'SLOT_ID_WEAPP_VIDEO_FEEDS') {
        return '小程序视频广告'
      } else if (unit === 'SLOT_ID_WEAPP_VIDEO_BEGIN') {
        return '小程序视频前贴'
      } else if (unit === 'SLOT_ID_WEAPP_BOX') {
        return '小程序格子广告'
      }
      return `其他广告: ${unit}`
    },
    // 分广告位数据
    async queryUnit () {
      this.queryCondSettlement.page = 1
      this.fetch(1)
    },
    // 曝光率数据格式化
    formatterExposureRate (row, col) {
      const val = row.exposure_rate
      return (val * 100).toFixed(2) + '%'
    },
    // ecpm数据格式化
    formatterEcpm (row, col) {
      return (row.ecpm / 100).toFixed(2)
    },
    // 收入数据格式化
    formatterIncome (row, col) {
      return (row.income / 100).toFixed(2)
    },
    // 点击率数据格式化
    formatterClickRate (row, col) {
      return (row.click_rate * 100).toFixed(2) + '%'
    },
    // 点击率数据格式化
    formatterClickRateCps (row, col) {
      return (row.click_rate).toFixed(2) + '%'
    },
    // 下单率数据格式化
    formatterOrderRate (row, col) {
      return (row.order_rate * 100).toFixed(2) + '%'
    },
    // 订单金额格式化
    formatterTotalFee (row, col) {
      return (row.total_fee / 100).toFixed(2)
    },
    // 预估收入格式化
    formatterCommission (row, col) {
      return (row.total_commission / 100).toFixed(2)
    },
    // cps
    async queryCps () {
      this.queryCps.page = 1
      this.fetch(2)
    },
    // 翻页
    changePageCps (page) {
      this.queryCondCps.page = page
      this.fetch(2)
    }
  }
}
</script>

<style scoped lang="less">
.home{
  .tab{
    ::v-deep .el-table{
    .cell {
      white-space: pre-line;
    }
  }
  }
}
</style>
