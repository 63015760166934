<template>
  <el-container style="height: 100vh">
    <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
      <el-menu router :default-active="$route.path">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>微信公众号</span>
          </template>
          <el-menu-item index="/wxmp/ad">广告查询</el-menu-item>
          <el-menu-item index="/wxmp/menu">自定义菜单</el-menu-item>
          <el-menu-item index="/wxmp/material">素材管理</el-menu-item>
          <el-menu-item index="/wxmp/reply">自定义回复</el-menu-item>
          <el-menu-item index="/wxmp/news">图文</el-menu-item>
        </el-submenu>

        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>商品</span>
          </template>
          <el-menu-item index="/goods/list">商品列表</el-menu-item>
          <el-menu-item index="/goods/create">添加商品</el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>订单</span>
          </template>
          <el-menu-item index="/order/create">创建订单</el-menu-item>
          <el-menu-item index="/order/list">订单列表</el-menu-item>
        </el-submenu>

        <el-menu-item index="/pay/list">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>支付</span>
          </template>
        </el-menu-item>

        <el-menu-item index="/loginRecord">登录记录</el-menu-item>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header>后台管理系统</el-header>
      <el-main>
        <router-view :key="$route.path" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Main',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="less">
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
