import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import http from './http'
Vue.prototype.$http = http

Vue.use(ElementUI)
Vue.use(Meta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  metaInfo () {
    return {
      meta: [
        // 解决微信公众号图片不能外部网站引用问题
        {
          name: 'referrer',
          content: 'never'
        }
      ]
    }
  },
  render: h => h(App)
}).$mount('#app')
