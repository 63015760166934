<template>
  <div>
    <el-table
      stripe
      :data="list"
      style="width: 100%"
      >
        <el-table-column
          prop="_id"
          label="订单id"
        />
        <el-table-column
          prop="userId"
          label="用户id"
        />
        <el-table-column
          prop="goodsList"
          label="商品列表"
          :formatter="formatOrderGoodsList"
        >
        </el-table-column>
        <el-table-column
          label="支付状态"
          :formatter="formatPayStatus"
        >
        <template slot-scope="scope">
            <el-tag :type="getTagColor(scope.row.payStatus)">{{ formatPayStatus(scope.row.payStatus) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="alipayTradeNo"
          label="支付宝订单号"
        />
        <el-table-column
          prop="payFee"
          label="支付金额"
        />
        <el-table-column
          prop="payTime"
          label="支付更新时间"
        />
        <el-table-column
          prop="remark"
          label="备注"
        />
        <el-table-column
        fixed="right"
        label="支付操作"
        width="400">
        <template slot-scope="scope">
          <el-button type="primary" @click="edit(scope.row)" size="small">编辑</el-button>
          <el-button type="primary" @click="qryPayStatus(scope.row)" size="small">付款查询</el-button>
          <el-button type="primary" @click="refund(scope.row)" size="small">退款</el-button>
          <el-button type="primary" @click="qryRefund(scope.row)" size="small">退款查询</el-button>
          <el-button type="danger" @click="closeTrade(scope.row)" size="small">关闭交易</el-button>
        </template>
        </el-table-column>
    </el-table>

    <el-dialog title="编辑订单" :visible.sync="dialogShow" >
      <el-button type="primary" @click="dialogShow=false" >关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      list: [],
      dialogShow: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('/order/payInfoList?dateFrom=2023-11-17T14:58:00.353Z&dateTo=2023.11.18')
      this.list = res.data
    },
    edit (row) {
      console.log(row)
      this.dialogShow = true
    },
    qryPayStatus (row) {},
    refund (row) {},
    qryRefund (row) {},
    closeTrade (row) {},
    // 格式化商品列表
    formatOrderGoodsList (row, col) {
      let str = ''
      for (const i of row.goodsList) {
        str += `goodsId: ${i.goodsId} 数量：${i.number} 单价：${i.price}\n`
      }
      return str
    },
    // 格式化支付状态
    formatPayStatus (status) {
      if (status === 0) {
        return '未支付'
      } else if (status === 1) {
        return '待支付'
      } else if (status === 2) {
        return '已支付'
      } else if (status === 3) {
        return '已退款'
      } else {
        return `其他状态${status}`
      }
    },
    // tag颜色
    getTagColor (status) {
      switch (status) {
        case 0:
          return 'info'
        case 1:
          return ''
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'warning'
      }
    }
  }
}

</script>

<style scoped lang="less">
.multi-line-text {
  white-space: pre-line;
}
</style>
