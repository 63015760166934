<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-date-picker
          v-model="timeRange"
          type="datetimerange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="changeTimeRange"
          >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-select v-model="cond.appName" placeholder="请选择appId" clearable>
          <el-option
            v-for="item in appIds"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input
          v-model="cond.openId"
          placeholder="请输入openId">
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-input
          v-model="cond.unionId"
          placeholder="请输入unionId">
        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="query">查询</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="reset">重置</el-button>
      </el-col>
    </el-row>

    <el-table
      stripe
      :data="list"
      style="width: 100%"
      >
        <el-table-column
          prop="_id"
          label="id"
        />
        <el-table-column
          prop="appName"
          label="App名称"
          width="100"
        />
        <el-table-column
          prop="openId"
          label="openId"
        >
        </el-table-column>
        <el-table-column
          prop="unionId"
          label="unionId"
        />
        <el-table-column
          label="登录时间"
          :formatter="formatTime"
        />
        <el-table-column
          prop="param"
          label="参数"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
        <template slot-scope="scope">
          <el-button type="primary" @click="detail(scope.row)" size="small">详情</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>

    <el-dialog title="登录详情" :visible.sync="dialogShow" >
      <div>
      </div>
      <el-button type="primary" @click="dialogShow=false" >关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      list: [],
      dialogShow: false,
      cond: {
        appName: '',
        openId: '',
        unionId: '',
        startTime: '',
        endTime: ''
      },
      timeRange: [],
      appIds: [
        'abc',
        'qw'
      ]
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      await this.query()
    },
    detail (row) {
      this.dialogShow = true
      console.log(row)
    },
    remove (row) {
      this.$confirm(`确定删除 "${row._id}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`/loginRecord/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    },
    // 格式化时间
    formatTime (row) {
      const t = row.loginTime.slice(0, 10)
      const time = new Date(parseInt(t) * 1000)
      const Y = time.getFullYear()
      const M = time.getMonth()
      const D = time.getDate()
      const h = time.getHours()
      const m = time.getMinutes()
      const s = time.getSeconds()
      let str = Y + '-' + (M + 1 < 10 ? '0' + (M + 1) : M + 1) + '-' + (D < 10 ? '0' : '') + D
      str += (' ' + (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s)
      return str
    },
    // 查询筛选
    async query () {
      const obj = this.cond
      const keys = Object.keys(obj)
      const queryStr = keys.map(key => `${key}=${obj[key]}`).join('&')
      const ret = await this.$http.get(`/loginRecord/query?${queryStr}`)
      console.log(ret.data)
      this.list = ret.data
    },
    // 重置筛选
    async reset () {
      this.cond = {
        appName: '',
        openId: '',
        unionId: '',
        startTime: '',
        endTime: ''
      }
      this.timeRange = []

      this.fetch()
    },
    // 选择日期时间
    changeTimeRange () {
      console.log(this.timeRange)
      this.cond.startTime = this.timeRange[0]
      this.cond.endTime = this.timeRange[1]
    }
  }
}

</script>

<style scoped lang="less">
.multi-line-text {
  white-space: pre-line;
}
</style>
