import axios from 'axios'
import Vue from 'vue'
import router from './router'

const HTTP_BASE_URL = process.env.VUE_APP_SERVER_API_URL

const http = axios.create({
  baseURL: HTTP_BASE_URL
})

http.getBaseUrl = function () {
  return HTTP_BASE_URL
}

// 请求拦截器
http.interceptors.request.use(function (config) {
  if (localStorage.token) {
    config.headers.Authorization = 'Bearer ' + localStorage.token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截器
http.interceptors.response.use(res => {
  return res
}, err => {
  if (err.response.data.message) {
    Vue.prototype.$message({
      type: 'error',
      message: err.response.data.message
    })
    if (err.response.status === 401) {
      router.push('/login')
    }
  }
  return Promise.reject(err)
})

export default http
