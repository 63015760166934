import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'

import AdminUserEdit from '@/views/AdminUserEdit.vue'
import AdminUserList from '@/views/AdminUserList.vue'
import WxmpAd from '@/views/WxmpAd.vue'
import WxmpCustomMenu from '@/views/WxmpCustomMenu.vue'
import WxmpMaterial from '@/views/WxmpMaterial.vue'
import WxmpCustomReply from '@/views/WxmpCustomReply.vue'
import WxmpNews from '@/views/WxmpNews.vue'
import GoodsList from '@/views/GoodsList.vue'
import GoodsEdit from '@/views/GoodsEdit.vue'
import OrderList from '@/views/OrderList.vue'
import OrderCreate from '@/views/OrderCreate.vue'
import PayList from '@/views/payList.vue'
import LoginRecord from '@/views/LoginRecord.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      { path: '/admin_user/create', component: AdminUserEdit },
      { path: '/admin_user/edit/:id', component: AdminUserEdit, props: true },
      { path: '/admin_user/list', component: AdminUserList },

      { path: '/wxmp/ad', component: WxmpAd },
      { path: '/wxmp/menu', component: WxmpCustomMenu },
      { path: '/wxmp/material', component: WxmpMaterial },
      { path: '/wxmp/reply', component: WxmpCustomReply },
      { path: '/wxmp/news', component: WxmpNews },

      { path: '/goods/list', component: GoodsList },
      { path: '/goods/edit/:id', component: GoodsEdit, props: true },
      { path: '/goods/create', component: GoodsEdit },

      { path: '/order/create', component: OrderCreate },
      { path: '/order/list', component: OrderList },

      { path: '/pay/list', component: PayList },
      { path: '/loginRecord', component: LoginRecord }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.isPublic && !localStorage.token) {
    return next('/login')
  }
  next()
})

export default router
