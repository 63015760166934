<template>
    <el-table
      stripe
      :data="list"
      style="width: 100%"
      >
        <el-table-column
          prop="title"
          label="标题"
        />
        <el-table-column
          prop="content"
          label="内容"
        />
        <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchEnable(scope.row)"></el-switch>
          <el-button type="primary" @click="$router.push(`/goods/edit/${scope.row._id}`)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      list: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('/goods')
      this.list = res.data
    },
    async switchEnable (row) {
      await this.$http.put(`/goods/${row._id}`, row)
      this.fetch()
    },
    remove (row) {
      this.$confirm(`确定删除 "${row.title}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`/goods/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    }
  }
}

</script>

<style scoped lang="less">
</style>
