<template>
    <div>
      <el-tabs v-model="curType" type="border-card" @tab-click="tabClick">
        <el-dialog title="查看图片">
        </el-dialog>

        <el-tab-pane label="图片" name="image">
          <el-button size="media" type="primary" @click="refresh('image')">获取数据</el-button>
          <!-- 图片表格 -->
          <el-table :data="imgData.list">
            <el-table-column label="图片" width="210">
              <template slot-scope="scope" >
                <el-image :src="scope.row.url" fit="contain" :preview-src-list="imgPrevList" style="width: 150px; height: 150px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="更新时间" width="200" :formatter="formatImgTime"></el-table-column>
            <el-table-column label="media_id" width="550" prop="media_id"></el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope" >
                <el-button size="mini" type="danger" @click="deleteItem(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="viewImageItem(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-pagination background :total="imgData.total" :page-size="pageSize" layout="prev, pager, next" @current-change="PageChage" :current-page="curImgPageIndex">
          </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="音频" name="voice">
          <el-button size="media" type="primary" @click="refresh('voice')">获取数据</el-button>
          <!-- 音频表格 -->
          <el-table :data="voiceData.list">
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="更新时间" width="200" :formatter="formatImgTime"></el-table-column>
            <el-table-column label="media_id" width="550" prop="media_id"></el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope" >
                <el-button size="mini" type="danger" @click="deleteItem(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="viewItem(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-pagination background :total="voiceData.total" :page-size="pageSize" layout="prev, pager, next" @current-change="PageChage" :current-page="curVoicePageIndex">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="视频" name="video">
          <el-button size="media" type="primary" @click="refresh('video')">获取数据</el-button>
          <!-- 音频表格 -->
          <el-table :data="videoData.list">
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="更新时间" width="200" :formatter="formatImgTime"></el-table-column>
            <el-table-column label="media_id" width="550" prop="media_id"></el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope" >
                <el-button size="mini" type="danger" @click="deleteItem(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="viewVideoItem(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-pagination background :total="videoData.total" :page-size="pageSize" layout="prev, pager, next" @current-change="PageChage" :current-page="curVideoPageIndex">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="临时素材" name="temp">临时素材

        </el-tab-pane>
        <el-tab-pane label="上传素材" name="upload">
          <!-- 文件上传 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form v-model="uploadForm" label-width="120px">
                <el-form-item label="文件类型">
                  <el-select v-model="uploadForm.fileType" placeholder="选择上传的文件类型">
                    <el-option v-for="item in fileTypeList" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="标题" >
                  <el-input v-model="uploadForm.title" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="描述" v-if="uploadForm.fileType === 'video'">
                  <el-input v-model="uploadForm.desc"></el-input>
                </el-form-item>

                <el-form-item label="文件">
                  <el-upload ref="upload"
                    :action="uploadUrl"
                    name="file"
                    :multiple="false"
                    :limit="1"
                    list-type="picture"
                    :auto-upload='false'
                    :data="uploadForm"
                    :before-upload="beforeUpload"
                    :on-error="errorUpload"
                    :on-success="successUpload">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                  </el-upload>

                  <img class="el-upload-list__item-thumbnail" v-if="uploadForm.fileType === 'image'"
                      :src="selPicUrl" alt=""
                    >
                </el-form-item>

                <el-form-item >
                  <el-button type="success" @click="uploadFile" :loading="uploading">上传素材</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      pageSize: 10, // 每页总数
      curType: 'image', // 当前选择tab
      uploadForm: {
        fileType: 'image',
        title: '',
        desc: ''
      }, // 上传文件表单数据
      uploadUrl: '', // 上传文件url
      selPicUrl: '', // 本地选择的图片
      uploading: false, // 上传文件中状态
      curImgPageIndex: 1, // 图片page index
      curVoicePageIndex: 1, // 语音page index
      curVideoPageIndex: 1, // 视频page index
      fileTypeList: [
        {
          label: '图片',
          value: 'image'
        },
        {
          label: '语音',
          value: 'voice'
        },
        {
          label: '视频',
          value: 'video'
        }
      ],
      imgData: {
        total: 0,
        offset: 0,
        list: []
      },
      imgPrevList: [],
      voiceData: {
        total: 0,
        offset: 0,
        list: []
      },
      videoData: {
        total: 0,
        offset: 0,
        list: []
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch(0)
    this.uploadUrl = this.$http.getBaseUrl() + '/wxmp/material_upload'
  },
  mounted () {},
  methods: {
    async fetch (offset) {
      const type = this.curType
      if (type !== 'image' && type !== 'voice' && type !== 'video') {
        return
      }
      const count = this.pageSize
      const res = await this.$http.get(`/wxmp/material_list?type=${type}&offset=${offset}&count=${count}`)

      if (this.curType === 'image') {
        this.imgData.offset = offset
        this.imgData.total = res.data.total_count
        this.imgData.list = res.data.item

        var imgPrevList = []
        for (const i in this.imgData.list) {
          imgPrevList.push(this.imgData.list[i].url)
        }
        this.imgPrevList = imgPrevList
      } else if (this.curType === 'voice') {
        this.voiceData.offset = offset
        this.voiceData.total = res.data.total_count
        this.voiceData.list = res.data.item
      } else if (this.curType === 'video') {
        this.videoData.offset = offset
        this.videoData.total = res.data.total_count
        this.videoData.list = res.data.item
      }
    },
    async refresh (type) {
      if (type === 'image') {
        this.curImgPageIndex = 1
        this.imgData.total = 0
        this.PageChage(1)
      } else if (type === 'voice') {
        this.curVoicePageIndex = 1
        this.voiceData.total = 0
        this.PageChage(1)
      } else if (type === 'video') {
        this.curVideoPageIndex = 1
        this.videoData.total = 0
        this.PageChage(1)
      }
    },
    async tabClick (val) {
      console.log(val.name, this.curType, this.voiceData)
      if (val.name === 'image' && (!this.imgData || this.imgData.list.length <= 0)) {
        this.refresh(val.name)
      } else if (val.name === 'voice' && (!this.voiceData || this.voiceData.list.length <= 0)) {
        this.refresh(val.name)
      } else if (val.name === 'video' && (!this.videoData || this.videoData.list.length <= 0)) {
        this.refresh(val.name)
      }
    },
    formatImgTime (row, col, val, index) {
      const time = new Date(row.update_time * 1000)
      const Y = time.getFullYear()
      const M = time.getMonth()
      const D = time.getDate()
      const h = time.getHours()
      const m = time.getMinutes()
      const s = time.getSeconds()
      let str = Y + '-' + (M + 1 < 10 ? '0' + (M + 1) : M + 1) + '-' + (D < 10 ? '0' : '') + D
      str += (' ' + (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s)
      return str
    },
    // 分页
    async PageChage (page) {
      const offset = this.pageSize * (page - 1)
      this.fetch(offset)
    },
    // 查看视频
    async viewVideoItem (row) {
      if (!row.media_id) {
        return
      }

      const url = this.$http.getBaseUrl() + `/wxmp/material_get?media_id=${row.media_id}`
      const ret = await this.$http.get(url)
      if (ret.data.down_url) {
        window.open(ret.data.down_url, '_blank')
      } else {
        this.$message({
          type: 'error',
          message: `操作失败, code=${ret.data.errcode}, msg=${ret.data.errmsg}`
        })
      }
    },
    // 查看图片
    async viewImageItem (row) {
      if (row.url) {
        window.open(row.url, '_blank')
      }
    },
    // 查看音频
    async viewItem (row) {
      if (!row.media_id) {
        return
      }

      const url = this.$http.getBaseUrl() + `/wxmp/material_get?media_id=${row.media_id}`
      window.open(url, '_blank')
    },
    // 删除
    async deleteItem (row) {
      if (!row.media_id) {
        return
      }

      this.$confirm('确定删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.get(`/wxmp/material_delete?media_id=${row.media_id}`)
        this.$message({
          type: res.data.errcode === 0 ? 'success' : 'error',
          message: res.data.errmsg === 'ok' ? '操作成功' : res.data.errmsg
        })

        // 删除成功后，刷新列表
        this.fetch(this.imgData.offset)
      })
    },
    getFile (val) {
      console.log(val)
    },
    beforeUpload (val) {
      console.log('before', val)
    },
    errorUpload (val) {
      this.uploading = false
      this.$message({
        type: 'error',
        message: `操作失败, code=${val.errcode}, msg=${val.errmsg}`
      })
    },
    successUpload (val) {
      this.uploading = false
      this.$message({
        type: val.media_id ? 'success' : 'error',
        message: val.media_id ? '操作成功' : '操作失败'
      })
    },
    // 上传文件
    async uploadFile () {
      this.uploading = true
      this.$refs.upload.submit()
    }
  }
}
</script>

<style scoped lang="less">
</style>
