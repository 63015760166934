<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="用户">
      <el-select v-model="form.userId" placeholder="选择用户">
        <el-option label="管理员" value="admin"></el-option>
        <el-option label="普通" value="123"></el-option>
      </el-select>
    </el-form-item>

    <el-row v-for="(item, index) in goodsList" :label="'商品' + `${index + 1}:`" :key="index">
      <el-col :span="5">
        <el-form-item :label="'商品' + (index + 1)">
          <el-select v-model="item.goodsId" placeholder="选择商品">
            <el-option label="商品aaa" value="aaa"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="价格">
          <el-input v-model="item.price"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="数量">
          <el-input v-model="item.number"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row >
      <el-col :span="5">
        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      form: {
        userId: '',
        goodsList: [],
        remark: '',
        orderId: '',
        payStatus: 0,
        alipayTradeNo: '',
        payFee: 0
      },
      goodsList: [
        {
          goodsId: '1234',
          price: '15.80',
          number: 1
        },
        {
          goodsId: '3435',
          price: '18.40',
          number: 4
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created () { },
  mounted () { },
  methods: {
    async onSubmit () {
      const data = this.form
      data.goodsList = this.goodsList
      data.payTime = new Date()
      data.orderId = ''

      const res = await this.$http.post('/order', data)
      console.log(res.data)
    }
  }
}

</script>

<style scoped lang="less">
.multi-line-text {
  white-space: pre-line;
}
</style>
