<template>
  <div class="home">
    <el-button type="primary" @click="getMenu">获取自定义菜单</el-button>
    <el-button type="danger" @click="deleteMenu">删除自定义菜单</el-button>
    <el-divider></el-divider>
    <div><WxmpMenuItem :data="menuData[0]"></WxmpMenuItem></div>
    <div><WxmpMenuItem :data="menuData[1]"></WxmpMenuItem></div>
    <div><WxmpMenuItem :data="menuData[2]"></WxmpMenuItem></div>
    <el-divider></el-divider>
    <el-button type="primary" @click="updateMenu">修改</el-button>
  </div>
</template>

<script>
import WxmpMenuItem from '@/components/WxmpMenuItem.vue'

export default {
  name: '',
  components: { WxmpMenuItem },
  data () {
    return {
      button: {},
      menuData: [{
        subItems: [{}, {}, {}, {}, {}]
      },
      {
        subItems: [{}, {}, {}, {}, {}]
      },
      {
        subItems: [{}, {}, {}, {}, {}]
      }],
      formData: {
        enable: true,
        name: '一级菜单',
        width: '100px',
        main: {
          type: 'view',
          key: '',
          url: 'http://www.baidu.com',
          appid: '',
          pagepath: ''
        },
        hasSub: true,
        subItems: [
          {
            enable: true,
            name: '子菜单1',
            type: 'view',
            key: '',
            url: 'http://g.cn',
            appid: '',
            pagepath: ''
          },
          {
            enable: false,
            name: '子菜单2',
            type: 'click',
            key: 'event a',
            url: '',
            appid: '',
            pagepath: ''
          },
          {
            enable: true,
            name: '子菜单3',
            type: 'click',
            key: 'event b',
            url: '',
            appid: '',
            pagepath: ''
          },
          {
            enable: true,
            name: '子菜单4_扫码',
            type: 'scancode_push',
            key: 'event c',
            url: '',
            appid: '',
            pagepath: ''
          },
          {
            enable: true,
            name: '子菜单5_小程序',
            type: 'miniprogram',
            key: '',
            url: 'https://mp.weixin.qq.com',
            appid: 'wx35252623',
            pagepath: '/page/index/index'
          }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () { },
  methods: {
    // 请求接口数据
    async fetch () {
      const url = 'wxmp/menu_get'
      const res = await this.$http.get(url)

      if (!res.data.selfmenu_info) {
        this.$alert(res.data.errmsg, '操作失败', {
          confirmButtonText: '确定'
        })
        return
      }

      this.button = res.data.selfmenu_info.button

      var obj = []

      obj[0] = this.setEmptyMenu()
      obj[1] = this.setEmptyMenu()
      obj[2] = this.setEmptyMenu()

      const len = this.button.length
      for (let i = 0; i < len; i++) {
        obj[i].enable = true
        obj[i].name = this.button[i].name

        if (this.button[i].sub_button && this.button[i].sub_button.list.length > 0) {
          obj[i].hasSub = true
          const subLen = this.button[i].sub_button.list.length
          for (let j = 0; j < subLen; j++) {
            for (const key in this.button[i].sub_button.list[j]) {
              obj[i].subItems[j][key] = this.button[i].sub_button.list[j][key]
            }
            obj[i].subItems[j].enable = true
          }
        } else {
          obj[i].hasSub = false
          obj[i].main = this.button[i]
        }

        if (res.data.is_menu_open === 0) {
          obj[i].enable = false
        }
      }

      this.menuData = obj

      // 错误提示
      if (!res.data.selfmenu_info) {
        this.menuData = res.data
        this.$message({
          type: 'error',
          message: 'error'
        })
      }
    },
    // 设置子菜单空对象
    setEmptySubMenu () {
      var obj = {
        enable: false,
        name: '',
        type: '',
        key: '',
        url: '',
        appid: '',
        pagepath: ''
      }
      return obj
    },
    // 设置空对象
    setEmptyMenu () {
      var objItem = (
        {
          enable: false,
          name: '',
          main: {
            type: '',
            key: '',
            url: '',
            appid: '',
            pagepath: ''
          },
          hasSub: false,
          subItems: [
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            },
            {
              enable: false,
              name: '',
              type: '',
              key: '',
              url: '',
              appid: '',
              pagepath: ''
            }
          ]
        }
      )
      return objItem
    },
    // 获取菜单
    async getMenu () {
      this.fetch()
    },
    // 删除菜单
    async deleteMenu () {
      const url = 'wxmp/menu_delete'
      const res = await this.$http.get(url)
      this.$message({
        type: res.data.errcode === 0 ? 'success' : 'error',
        message: res.data.errmsg === 'ok' ? '操作成功' : res.data.errmsg
      })

      if (res.data.errcode === 0) {
        this.getMenu()
      }
    },
    // 修改菜单
    async updateMenu () {
      var obj = { button: [] }

      for (let j = 0; j < 3; j++) {
        if (this.menuData[j].enable) {
          const bHasSub = this.menuData[j].hasSub
          if (bHasSub) {
            var subBtn = []
            for (let i = 0; i < this.menuData[j].subItems.length; i++) {
              if (this.menuData[j].subItems[i].enable) {
                subBtn.push(this.menuData[j].subItems[i])
              }
            }

            obj.button.push({
              name: this.menuData[j].name,
              sub_button: subBtn
            })
          } else {
            var main = this.menuData[j].main
            main.name = this.menuData[j].name
            obj.button.push(main)
          }
        }
      }

      const url = 'wxmp/menu_create'
      const res = await this.$http.post(url, obj)

      this.$message({
        type: res.data.errcode === 0 ? 'success' : 'error',
        message: res.data.errmsg === 'ok' ? '操作成功' : res.data.errmsg
      })

      if (res.data.errcode === 0) {
        this.getMenu()
      } else {
        this.$alert(res.data.errmsg, '操作失败', {
          confirmButtonText: '确定'
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
</style>
