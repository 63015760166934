<template>
    <div>
      <el-tabs v-model="curType" type="border-card" @tab-click="tabClick">
        <el-dialog title="查看图文">
        </el-dialog>

        <el-tab-pane label="图文" name="image">
          <el-button size="media" type="primary" @click="refresh()">获取数据</el-button>
          <!-- 图片表格 -->
          <el-table :data="newsData.list">
            <el-table-column label="图片" width="210">
              <template slot-scope="scope" >
                <el-image :src="scope.row.url" fit="contain" :preview-src-list="imgPrevList" style="width: 150px; height: 150px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="更新时间" width="200" :formatter="formatImgTime"></el-table-column>
            <el-table-column label="media_id" width="550" prop="media_id"></el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope" >
                <el-button size="mini" type="danger" @click="deleteItem(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="viewImageItem(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-pagination background :total="newsData.total" :page-size="pageSize" layout="prev, pager, next" @current-change="PageChage" :current-page="curImgPageIndex">
          </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="草稿箱" name="temp">草稿箱

        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      pageSize: 10, // 每页总数
      newsData: {
        total: 0,
        offset: 0,
        list: []
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch(0)
    this.uploadUrl = this.$http.getBaseUrl() + '/wxmp/material_upload'
  },
  mounted () {},
  methods: {
    async fetch (offset) {
      const count = this.pageSize
      const res = await this.$http.get(`/wxmp/material_list?type=news&offset=${offset}&count=${count}`)

      this.newsData.offset = offset
      this.newsData.total = res.data.total_count
      this.newsData.list = res.data.item

      var imgPrevList = []
      for (const i in this.newsData.list) {
        imgPrevList.push(this.newsData.list[i].url)
      }
      this.imgPrevList = imgPrevList
    },
    async refresh () {
      this.curImgPageIndex = 1
      this.newsData.total = 0
      this.PageChage(1)
    },
    async tabClick (val) {
      console.log(val)
    },
    // 分页
    async PageChage (page) {
      const offset = this.pageSize * (page - 1)
      this.fetch(offset)
    },
    // 删除
    async deleteItem (row) {
      if (!row.media_id) {
        return
      }

      this.$confirm('确定删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.get(`/wxmp/material_delete?media_id=${row.media_id}`)
        this.$message({
          type: res.data.errcode === 0 ? 'success' : 'error',
          message: res.data.errmsg === 'ok' ? '操作成功' : res.data.errmsg
        })

        // 删除成功后，刷新列表
        this.fetch(this.newsData.offset)
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
