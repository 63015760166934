<template>
  <div>
    <el-button size="media" type="primary" @click="addData">添加数据</el-button>
    <el-dialog :title="(form.keywords ? '修改' : '新增') + '自定义回复'" :visible.sync="dialogFormVisible" modal>
      <el-form :model="form">
        <el-form-item label="消息类型" :label-width="formLabelWidth">
          <el-select v-model="form.type" placeholder="请选择消息类型">
            <el-option label="文本" value="text"></el-option>
            <el-option label="语音" value="voice"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" :label-width="formLabelWidth">
          <el-input v-model="form.keywords" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="回复类型" :label-width="formLabelWidth">
          <el-select v-model="form.replyType" placeholder="请选择消息类型" @change="replyTypeChange">
            <el-option label="文本" value="text"></el-option>
            <el-option label="语音" value="voice"></el-option>
            <el-option label="图片" value="image"></el-option>
            <el-option label="视频" value="video"></el-option>
            <el-option label="图文" value="news"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回复文本" :label-width="formLabelWidth" v-if="form.replyType === 'text'">
          <el-input v-model="form.body" autocomplete="off" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="回复素材" :label-width="formLabelWidth" v-if="form.replyType !== 'text'">
          <el-image v-if="form.replyType === 'image'"
              style="width: 200px; height: 200px"
              :src="dlgSelectImgUrl"
              fit="fill">
            </el-image>
            <el-input v-model="dlgSelectImg" autocomplete="off" ></el-input>
          <el-button type="primary" @click="dialogPicSelVisible=true">选择素材</el-button>
          <el-button type="danger" @click="dlgSelectImgUrl='',dlgSelectImg=''">清除选择</el-button>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-switch v-model="form.enable" autocomplete="off"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择图片" :visible.sync="dialogPicSelVisible" modal>
      <el-table :data="selImgList">
        <el-table-column  label="图片" width="220">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.url"
              fit="fill">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" ></el-table-column>
        <el-table-column  label="选择">
          <template slot-scope="scope">
            <el-radio  v-model="dlgSelectImg" :label="scope.row.media_id" border @input="imgRadioChange">使用</el-radio>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click="btnSelImgOk">确定</el-button>
    </el-dialog>
    <el-table
      stripe
      :data="items"
      style="width: 100%">
      <el-table-column
        prop="_id"
        label="ID"
        width="220">
      </el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        width="100">
      </el-table-column>
      <el-table-column
        prop="keywords"
        label="关键字"
        width="200">
      </el-table-column>
      <el-table-column
        prop="replyType"
        label="回复类型"
        width="100">
      </el-table-column>
      <el-table-column
        prop="body"
        label="文本">
      </el-table-column>
      <el-table-column
        prop="media_id"
        label="素材id">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchEnable(scope.row)" style="padding-right: 10px"></el-switch>
          <el-button type="primary" @click="edit(scope.row)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      items: [],
      form: {},
      dialogFormVisible: false,
      dialogPicSelVisible: false,
      formLabelWidth: '100px',
      editMode: false,
      selImgList: [], // dialog图片选择列表
      dlgSelectImg: '',
      dlgSelectImgUrl: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('/wxmp/msg_reply_list')
      this.items = res.data
    },
    async addData () {
      this.dialogFormVisible = true
      this.form = {}
      this.editMode = false
    },
    async switchEnable (row) {
      await this.$http.post('/wxmp/msg_reply_enable', row)
      this.fetch()
    },
    async submit () {
      this.dialogFormVisible = false

      if (this.editMode) {
        // 修改
        const ret = await this.$http.post(`/wxmp/msg_reply_modify?_id=${this.form._id}`, this.form)
        if (ret) {
          this.fetch()
        }
      } else {
        // 新增
        const ret = await this.$http.post('/wxmp/msg_reply_add', this.form)
        if (ret) {
          this.fetch()
        }
      }
    },
    async edit (row) {
      this.dialogFormVisible = true
      var str = JSON.stringify(row)
      this.form = JSON.parse(str)
      this.editMode = true
    },
    remove (row) {
      this.$confirm(`确定删除 "${row.keywords}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.post('wxmp/msg_reply_delete', { _id: row._id })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    },
    async replyTypeChange (type) {
      this.dlgSelectImg = ''
      this.dlgSelectImgUrl = ''

      if (type === 'text') {
        return
      }
      const offset = 0
      const list = await this.$http.get(`/wxmp/material_list?type=${type}&offset=${offset}&count=20`)
      this.selImgList = list.data.item
    },
    imgRadioChange (val) {
      this.dlgSelectImg = val
      for (const i in this.selImgList) {
        if (this.selImgList[i].media_id === val) {
          this.dlgSelectImgUrl = this.selImgList[i].url
          this.form.media_id = this.dlgSelectImg
          this.form.body = this.selImgList[i].name
          break
        }
      }
    },
    btnSelImgOk () {
      this.dialogPicSelVisible = false
    }
  }
}
</script>

<style scoped lang="less">
</style>
